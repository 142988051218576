import React, {useState, useEffect} from "react"
import {Container,Button,Navbar,Nav} from 'react-bootstrap';
import { Link, navigate } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-input-range/lib/css/index.css'
import  "../../assests/scss/pages/app-pre.scss"; 
import  "../../assests/scss/pages/app-intro.scss"; 
import  "../../assests/scss/pages/seller2.scss"; 
import  "../../assests/scss/pages/custom.scss"; 
import ProfileImg from "../../assests/images/avatar_img.svg";
import LogoImg from "../../assests/images/logo.svg";

import {isLoggedIn, isBrowser, logout, getUser } from "../../services/auth"
import { useContainerContext } from "../../store/ContainerContext";

import { GET_USER_DETAILS } from "../../queries/common_use_query";

const constant = require('./constant');

const Header = props => {
  var get_property_id = '', json_parse = '';
  const [isAuth, setIsAuth] = useState(isLoggedIn());
  const context = useContainerContext();
  const { userInfoDispatch } = context.actions;
  const { logged_user_data } = context.state;
  const user = getUser();

  const { loading, error, data } = GET_USER_DETAILS(props.user_email);

  useEffect(() => {
    data && data.users.length > 0 && userInfoDispatch(data.users[0]);
  }, [data]);

  
  //console.log("user_data", props.user_email, data)

  return <>
    
    <header className="header">
      <Container>
          <div className="header-wrapper">
            <a className="logo">
              <img src={LogoImg} alt="logo-img" />
            </a>
            {
              props.hide_right_side !== true && (
                <div className="header-right">
                  <div className="profile-img">
                    <img src={logged_user_data && logged_user_data.photo ? logged_user_data.photo : ProfileImg} alt="profile-img" />
                  </div>
                  <div className="contact-text">
                    {logged_user_data && logged_user_data.phone_number && <a href={`tel:${logged_user_data && logged_user_data.phone_number}`} className="contact-link">{logged_user_data && logged_user_data.phone_number}</a>}
                    {
                      logged_user_data && logged_user_data.phone_number && (
                        <span><a href={`tel:${logged_user_data.phone_number ? logged_user_data.phone_number : ''}`} className="contact-num">Need help?  <span>Call {logged_user_data.First_Name ? logged_user_data.First_Name : logged_user_data.first_name} on</span> <mobile_no>{logged_user_data.phone_number ? logged_user_data.phone_number : ''}</mobile_no> </a></span>  
                      )
                    }
                                     
                  </div>
                  {/* contact-text */}
                </div>
              )
            }
            
          </div>
        </Container>
        {/* container */}
      </header>
      {/* header */}
  </>
}

export default Header