import React, {useState} from "react"
import {Container,Row,Col,Navbar,Nav} from 'react-bootstrap';
import { Link } from "gatsby"
import dateFormat from 'dateformat';
import VideoImg1 from "../../assests/images/intro_img.jpg"; 
import FeedbackIcon from "../../assests/images/feedback_icon.svg";

import CustomerFeedback from  "./customer-feedback"; 
import SampleVideos from  "./work-sample-videos";
import SamplePhotos from  "./work-sample-photos"; 

import Title_Block from  "../modules/title_block"; 

import {config} from '../../components/Common/constant';

import { useContainerContext } from "../../store/ContainerContext";

import { GET_PHOTOGRAPHER_PROPERTY } from "../../queries/common_use_query";

const Photographer = (props) => {
    const context = useContainerContext();
    const { property_launch_date_time, photographer_date_time } = context.state;
    const [photo_name, setPhotoName] = useState('');
    const [open_feedback, setFeedback] = useState(false);
    const [property_data, setPropertyData] = useState(props.property_data);

    var photographer_email = photographer_date_time.Photographer ? photographer_date_time.Photographer : property_data.photographer1 && property_data.photographer1.Email;

    const { loading:photographer_loading, error:photographer_error, data:photographer_data } = GET_PHOTOGRAPHER_PROPERTY(photographer_email);

    const OpenFeedback = (photo_name) => {
      setPhotoName(photo_name);
      setFeedback(true);
    }

    const CloseFeedback = () => {
      setPhotoName('');
      setFeedback(false);
    }

    //console.log("open_feedback", open_feedback)

    var photographer = photographer_data && photographer_data.photographers.length > 0 ? photographer_data.photographers[0] : property_data.photographer1 ? property_data.photographer1 : '';

    //console.log("photographer",photographer);

    var photographer_testi = [];
    if(photographer){
      if(photographer.Testimonial1Quote){
        photographer_testi.push({'Client': photographer.Testimonial1Client,'Date': photographer.Testimonial1Date,'Quote': photographer.Testimonial1Quote})
      }
      if(photographer.Testimonial2Quote){
        photographer_testi.push({'Client': photographer.Testimonial2Client,'Date': photographer.Testimonial2Date,'Quote': photographer.Testimonial2Quote})
      }
      if(photographer.Testimonial3Quote){
        photographer_testi.push({'Client': photographer.Testimonial3Client,'Date': photographer.Testimonial3Date,'Quote': photographer.Testimonial3Quote})
      }
      
    }


    //console.log("photographer_date_time", photographer_date_time.Photographer)
    

    return <>

      <div className={`section-wrapper ${open_feedback === true ? 'hide' : ''}`}>
          <Container>

            <Title_Block Title={props.Title ? props.Title : "Introducing your Professional Photographer"} Type={props.Type} PreviousLink={''} NextLink={''} NextPage={props.NextPage} PrevPage={props.PrevPage} instruct={false} />

            {photographer && 
              <div className="employee-wrapper">

                  <div className="employment-right people_img">
                   
                   <div className="video-wrap">
                     {
                       photographer.Photo &&
                       <figure>
                         {/* <img src={photographer.Photo} alt="video-img" /> */}
                         <div className="introImg" style={{backgroundImage:`url("${photographer.Photo}")`}}></div>
                       </figure>
                     }
                     {/* <Link to="#">
                     <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <g clip-path="url(#clip0)">
                     <path d="M20.8411 10.5974L3.55536 0.326278C2.15089 -0.50785 0 0.3016 0 2.36471V22.902C0 24.7529 1.99866 25.8684 3.55536 24.9405L20.8411 14.6743C22.383 13.7612 22.3879 11.5105 20.8411 10.5974Z" fill="#CC0133"/>
                     </g>
                     <defs>
                     <clipPath>
                     <rect width="22" height="25.2703" fill="white"/>
                     </clipPath>
                     </defs>
                     </svg>
                     </Link> */}
                   </div>
               </div>
               {/* video-wrap */}

                <div className="employee-content">
                  <div className="employee-top">
                    <h3>{photographer.Name}</h3>
                    <span>{photographer.Title}</span>
                  </div>
                  {/* top-content */}

                  {/* {photographer_testi && photographer_testi.length > 0 && 
                    <div className="feedbackLink">
                      <a href="javascript:;" onClick={() => OpenFeedback(photographer.Name)}><img src={FeedbackIcon} alt="" />My Customer Feedback</a>
                    </div>
                  } */}
                  
                  <p>{photographer.Bio}</p>

                  {
                    photographer_testi && photographer_testi.length > 0 && 
                      <div className="content-sec-wrap customer_feedback">
                          <h2>My Customer Feedback</h2>
                          <div className="content-block-wrap">
 
                          {photographer_testi.map((item, index)=>{
                                  return(
                                    <div className="content-block">
                                          <p>“{item.Quote}”</p>
                                          <div className="content-bottom">
                                              <strong>{item.Client}</strong>
                                              <strong>{item.Date && dateFormat(item.Date, "mmm dd, yyyy")}</strong>
                                          </div>
                                      </div>
                                  )
                              }) 
                          }
                          </div>
                          {/* content-block */}
                      </div>
                  }

                  {/* <div className="content-block">
                    <strong>
                        Location:
                    </strong>
                    <span>
                        Gledhow, Leeds -
                    </span>
                  </div> */}
                  {/* content-block */}

                  {/* {
                    photographer.TelephoneNumber && (
                      <div className="content-block">
                        <strong>
                            Telephone:
                        </strong>
                        <a href={`tel:${photographer.TelephoneNumber}`}>{photographer.TelephoneNumber}</a>
                      </div>
                    ) 
                  } */}
                  
                  {/* content-block */}
                  {/* <div className="content-block">
                    <strong>
                        Email Address:
                    </strong>
                    <a href={`mailto:${photographer.Email}`}>{photographer.Email}</a>
                  </div> */}
                  {/* content-block */}
                </div>
                {/* employment-content */}

                
              </div>
            }

            {/* employee-wrapper */}
          </Container>
      </div>
      {/* section-wrapper */}
    
      {!open_feedback && 
        <div className="section-wrapper property-sec-wrap property-sec-block2 sample_videos">
          <Container>
            <div className="example-wrapper">
              <h2>Work Samples</h2>

              <SamplePhotos PhotographerPhotos={photographer && photographer.samplePhoto && photographer.samplePhoto.length > 0 ? photographer.samplePhoto : []} />

              <SampleVideos PhotographerVideos={photographer && photographer.tourSample360 && photographer.tourSample360.length > 0 ? photographer.tourSample360 : []} />
            </div>
          </Container>
        </div>
      }
      {/* section-wrapper */}

      <div className={`section-wrapper customer_feedback ${open_feedback === false ? 'hide' : ''}`}>
          <Container>
            <h2>{photo_name} Customer Feedback <a href="javascript:;" className="feed_close" onClick={CloseFeedback}>X</a></h2>

            <CustomerFeedback photographer_testi={photographer_testi} />
          </Container>
      </div>      
      {/* customer feedback */}

    </>
}

export default Photographer
