import React, { useEffect, Component, useState } from "react"
import {Container,Table, Dropdown, Row, Col, Button} from 'react-bootstrap';
import FsLightbox from 'fslightbox-react';

const PhotoGallery = (props) => {

    const [activeItemIndex, setActiveItemIndex] = useState(0);

    const [lightboxController, setLightboxController] = useState({
      toggler: false,
      slide: 0
    });
    const openLighboxMobile = (e, ind) => {
        e.preventDefault();
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: ind
        });
    }

    var GalleryImages = [{'src_url': '', 'title': 'Photography', 'type': 'image'}, {'src_url': '', 'title': '360 Degree Tour', 'type': 'image'}, {'src_url': '', 'title': 'Photography', 'type': 'image'}];

    var lightImages2 = GalleryImages && GalleryImages.map(img => img.src_url);


  return (
    <>
        <FsLightbox
            toggler={lightboxController.toggler}
            sources={lightImages2}
            slide={lightboxController.slide}
        />

        <div className="sell_properties photo_gallery">
            <Row>
                
                <Col lg={12} md={12}>
                    {/* <p className="fig-content font_18">Photo from previous property advert 10/11/15.</p> */}
                    <Row className="pt-3">    
                    {
                        GalleryImages.map((item, index) => {  
                        return(
                            <Col lg={4} md={4}>
                                <div className="map-card">
                                {
                                    item.src_url ? (
                                    <a href="javascript:;" onClick={(e) => openLighboxMobile(e, index + 1)}>
                                    <figure>
                                        <img src={item.src_url} alt="map-img" />
                                    </figure>
                                    </a>
                                    ) : (
                                    <span className="coming_soon">Image Coming Soon</span>
                                    )
                                }
                                </div>
                            </Col>
                        )
                        })
                    }
                    </Row>
                </Col>    
            </Row>
        </div>
    </>
  )
}

export default PhotoGallery