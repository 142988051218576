import React, {useState, useEffect} from "react"
import {Container,Navbar,Nav, Row} from 'react-bootstrap';
import { Link } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-input-range/lib/css/index.css'

import major_prop_icon from '../../assests/images/major_prop_icon.svg';
import prop_launch_icon from '../../assests/images/prop_launch_icon.svg';
import rightmove_icon from '../../assests/images/rightmove_icon.svg';
import giraffe_icon from '../../assests/images/giraffe_icon.svg';
import service_support_icon from '../../assests/images/service_support_icon.svg';
import call_icon from '../../assests/images/call_icon.svg';
import i247_icon from '../../assests/images/24x7_icon.svg';
import zoopla_icon from '../../assests/images/zoopla_icon.svg';
import prof_photo_icon from '../../assests/images/prof_photo_icon.svg';
import android_icon from '../../assests/images/android_icon.svg';

import {config} from '../../components/Common/constant';

import HTMLReactParser from 'html-react-parser';
import { GET_ARTICLE } from "../../queries/common_use_query";

import VideoModule from "./video-module"

import Title_Block from  "../modules/title_block"; 

const ProductMix = (props) => {

    const [ property_data, setPropertyData ] = useState(props.property_data);
    const [ article_data, setArticleData ] = useState('');
    const { loading, error, data } = GET_ARTICLE('product-mix');

    useEffect(()=>{
        setArticleData(data && data.articles[0]);
    }, [data]);

    var strapi_url = process.env.GATSBY_STRAPI_SRC;

    //console.log("article_data", article_data);

    return <>
      <div className="section-wrapper">
          <Container>

            <Title_Block Title={article_data && article_data.Title} Type={props.Type} PreviousLink={''} NextLink={''} instruct={true}/>

            {article_data && article_data.Content && <p className="fig-content mb_cnt font_18">{ HTMLReactParser(article_data.Content)}</p>}

            <div className="employee-wrapper">
                
                <ul className="market_camp">
                    {property_data.product_specifications && property_data.product_specifications.AllMajorPropertyPortals && <li><img src={major_prop_icon} alt="All major property portals" title="All major property portals" /> <span>All major property portals</span></li> }

                    {property_data.product_specifications && property_data.product_specifications.Android10DayCampaign && <li><img src={android_icon} alt="Android 10 day campaign" title="Android 10 day campaign" /> <span>Android 10 day campaign</span></li>}

                    {property_data.product_specifications && property_data.product_specifications.AndroidRetargeting && <li><img src={android_icon} alt="Android 10 day campaign with Retargeting" title="Android 10 day campaign with Retargeting" /> <span>Android 10 day campaign with Retargeting</span></li>}

                    {property_data.product_specifications && property_data.product_specifications.Giraffe360Tour && <li><img src={giraffe_icon} alt="Giraffe 360 Tour" title="Giraffe 360 Tour" /> <span>Giraffe 360 Tour</span></li>}

                    {property_data.product_specifications && property_data.product_specifications.ProfessionalPhotography && <li><img src={prof_photo_icon} alt="Professional Photography" title="Professional Photography" /> <span>Professional Photography</span></li>}

                    {property_data.product_specifications && property_data.product_specifications.PropertyLaunchEvent && <li><img src={prop_launch_icon} alt="Property Launch Event" title="Property Launch Event" /> <span>Property Launch Event</span></li>}

                    {property_data.product_specifications && property_data.product_specifications.RightmoveFeaturedProperty && <li><img src={rightmove_icon} alt="Rightmove Featured Property" title="Rightmove Featured Property" /> <span>Rightmove Featured Property</span></li>}

                    {property_data.product_specifications && property_data.product_specifications.RightmovePremiumListing && <li><img src={rightmove_icon} alt="Rightmove Premium Listing" title="Rightmove Premium Listing" /> <span>Rightmove Premium Listing</span></li>}
                    
                    {property_data.product_specifications && property_data.product_specifications.ZooplaPremiumListing && <li><img src={zoopla_icon} alt="Zoopla Premium Listing" title="Zoopla Premium Listing" /> <span>Zoopla Premium Listing</span></li>}

                    {property_data.product_specifications && property_data.product_specifications.OnlineViewingBooking && <li><img src={i247_icon} alt="24/7 Online Viewing Booking" title="24/7 Online Viewing Booking" /> <span>24/7 Online Viewing Booking</span></li>}

                    {property_data.product_specifications && property_data.product_specifications.SevenDayLeadHandling && <li><img src={call_icon} alt="7 Day per week call handling (8 am until 8pm)" title="7 Day per week call handling (8 am until 8pm)" /> <span>7 Day per week call handling (8 am until 8pm)</span></li>}

                    {property_data.product_specifications && property_data.product_specifications.DedicatedClientServiceTeam && <li><img src={service_support_icon} alt="Dedicated Client Service Manager and Client Service Support" title="Dedicated Client Service Manager and Client Service Support" /> <span>Dedicated Client Service Manager and Client Service Support</span></li>}
                </ul>
                
            </div>

          </Container>
      </div>
    </>
}

export default ProductMix