import React,{useState, useEffect} from "react"
import { Link, navigate } from "gatsby"
import {Container,Navbar,Nav, Button} from 'react-bootstrap';
import $ from 'jquery';
import {config} from '../Common/constant';
import { useContainerContext } from "../../store/ContainerContext";
import {isBrowser} from "../../services/auth"

import ItemsCarousel from 'react-items-carousel';
import rightArw from "../../assests/images/right-arrow.svg";
import leftArw from "../../assests/images/left-arrow.svg";
import Slider_Navbar from "./Slider_Navbar";



export const DesktopNavbar = (props) => {
    const context = useContainerContext();
    const { preview_tab, user_tracking } = context.state;
    const { PreviewTabDispatch, UserTrackingDispatch } = context.actions;
    const [property_data, setPropertyData] = useState('');

    useEffect(()=>{
        setPropertyData(props.property_data);
    },[props.property_data])

    const navigateLink = (e,tab_name, prev_tab, next_tab) => {
        //console.log('link---', link)
        //navigate(link);
        if(isBrowser()){
            window.scrollTo(0, 0);
        }        
        PreviewTabDispatch({current_tab:tab_name, prev_tab:prev_tab, next_tab:next_tab});
    }


    const prevLink = (e) => {
        document.querySelector(".actv.nav-link").previousSibling.click();
    }
    const nextLink = () => {
        document.querySelector(".actv.nav-link").nextSibling.click();
    }

    return(
        <Navbar>
                <Navbar.Toggle aria-controls="basic-navbar-nav" className="hamburger">
                <span className="hambuger-lines"></span>
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav" className={props.PresentType === "preval" ? '' : 'custom_nav'}>

                    <a href={'javascript:;'} onClick={prevLink} className={`left-icon nav_btn ${preview_tab.prev_tab === "first" ? 'no_link' : ''}`}>  
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1785 0.678711L14.9463 2.44648L7.70517 9.6876L14.9463 16.9287L13.1785 18.6965L4.16964 9.6876L13.1785 0.678711Z" fill={preview_tab.prev_tab === "first" ? "#67707B" : "#fff"}/>
                        </svg> <span>Previous</span>
                    </a>

                    <Nav>
                        <Nav.Link href={"javascript:;"} onClick={(e) => navigateLink(e, "Intro", 'first', "")} className={props.tab_name === "Intro" ? 'actv' : ''} >Intro</Nav.Link>
                        {/* 
                        <Nav.Link href={"javascript:;"} onClick={(e) => navigateLink(e, "Property Details", 'Intro', "Seller Details")} id={"property_id"} className={`${props.tab_name === "Property Details" ? 'actv' : ''}`} >Property Details</Nav.Link>
                        <Nav.Link href={"javascript:;"} onClick={(e) => navigateLink(e, "Seller Details", 'Property Details', "Case Studies")} className={props.tab_name === "Seller Details" ? 'actv' : ''} >Seller Details</Nav.Link> */}

                        {
                            props.PresentType === "preval" ? (
                                <>
                                    <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Launch", "", "")} className={props.tab_name === "Launch" ? 'actv' : ''}>Launch</Nav.Link>
                                    <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Case Studies", "", "")} className={props.tab_name === "Case Studies" ? 'actv' : ''}>Case Studies</Nav.Link>
                                    <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "People", '', "")} className={props.tab_name === "People" ? 'actv' : ''}>People</Nav.Link>
                                    <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Marketing", '', "")} className={props.tab_name === "Marketing" ? 'actv' : ''}>Marketing</Nav.Link>
                                    <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Valuation Day", '', '')} className={props.tab_name === "Valuation Day" ? 'actv' : ''}>Valuation Day</Nav.Link>
                                    <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Thank You", '', 'last')} className={props.tab_name === "Thank You" ? 'actv' : ''}>Thank You</Nav.Link> 
                                </>
                            ) : (
                                <>
                                    

                                    {props.PresentType !== "custom_present" && <>
                                        
                                        <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Case Studies", "", "")} className={props.tab_name === "Case Studies" ? 'actv' : ''}>Case Studies</Nav.Link>

                                        <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Launch", "", "")} className={props.tab_name === "Launch" ? 'actv' : ''}>Launch</Nav.Link>

                                        {
                                            props.PresentType === "custom_agent" && 
                                            <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Schedule", "", "")} className={props.tab_name === "Schedule" ? 'actv' : ''}>Schedule</Nav.Link>
                                        }

                                        

                                        {/* {property_data && parseInt(property_data.price_expectancy) > 200000 && <>
                                            <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Plan", '', "")} className={props.tab_name === "Plan" ? 'actv' : ''}>Plan</Nav.Link>
                                            </>
                                        } */}

                                        
                                        <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Plan", '', "")} className={props.tab_name === "Plan" ? 'actv' : ''}>Plan</Nav.Link>
                                          
                                        
                                        <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Team", '', '')} className={props.tab_name === "Team" ? 'actv' : ''}>Team</Nav.Link>

                                        {/* <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Photographer", '', '')} className={props.tab_name === "Photographer" ? 'actv' : ''}>Photographer</Nav.Link> */}

                                        <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Previewings", '', "")} className={props.tab_name === "Previewings" ? 'actv' : ''}>Buyers</Nav.Link>
                                         

                                        <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Marketing", '', "")} className={props.tab_name === "Marketing" ? 'actv' : ''}>Marketing</Nav.Link>

                                        <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Comparables", '', "")} className={props.tab_name === "Comparables" ? 'actv' : ''}>Comparables</Nav.Link>
                                    </>}

                                    { props.PresentType === "custom_present" && property_data.product_specifications && (
                                        <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Campaign", '', "")} className={props.tab_name === "Campaign" ? 'actv' : ''}>Campaign</Nav.Link>
                                    )}

                                    <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Fees", '', "")} className={props.tab_name === "Fees" ? 'actv' : ''}>Price/Fees</Nav.Link>

                                    {/* {props.PresentType === "custom_agent" || props.PresentType === "custom_present" && (
                                        <>
                                            <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Contract Form", '', "")} className={props.tab_name === "Contract Form" ? 'actv' : ''}>Contract Page</Nav.Link>
                                        </>
                                        )
                                    } */}
                                    <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Instruct", '', props.PresentType === "custom_client" ? "" : "last")} className={props.tab_name === "Instruct" ? 'actv' : ''}>Instruct</Nav.Link>

                                    { props.PresentType === "custom_client" && 
                                        <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Thank You", '', 'last')} className={props.tab_name === "Thank You" ? 'actv' : ''}>Thank You</Nav.Link>
                                    }
                                </>
                            )
                        }
                        
                                      
                    </Nav>

                    <a href={'javascript:;'} onClick={nextLink} className={`right-icon nav_btn ${preview_tab.next_tab === "last" ? 'no_link' : ''}`}>
                        <span>Next</span> <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.82148 18.6963L5.05371 16.9285L12.2948 9.68741L5.05371 2.44629L6.82148 0.678522L15.8304 9.68741L6.82148 18.6963Z" fill={preview_tab.next_tab === "last" ? "#67707B" : "#fff"}/>
                        </svg>
                    </a>
                </Navbar.Collapse>
            </Navbar>
            
    )
}

function NavLink(props){

    return(
    <>
        <div className="footer-wrapper">
            <Container>
            <div className="footer-wrap">
                <div className="desk_nav"><DesktopNavbar {...props} /></div>

                {/* <div className="mobile_nav nav_slider"><Slider_Navbar {...props} /></div> */}
            </div>
            {/* footer-wrap */}         
            </Container>
        </div>
        {/* footer-wrapper */}
    </>
    )
}

export default NavLink