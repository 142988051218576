import React,{useState, useEffect} from "react"
import { Link, navigate } from "gatsby"
import {Container,Navbar,Nav, Button} from 'react-bootstrap';
import $ from 'jquery';
import {config} from '../Common/constant';
import { useContainerContext } from "../../store/ContainerContext";
import {isBrowser} from "../../services/auth"

import ItemsCarousel from 'react-items-carousel';
import rightArw from "../../assests/images/right-arrow.svg";
import leftArw from "../../assests/images/left-arrow.svg";
import {useWindowDimensions} from "../../components/utils/common";

function Slider_NavLink(props){

    //console.log('property_data',property_data);
    const { height, width } = useWindowDimensions();
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    
    const context = useContainerContext();
    const { preview_tab } = context.state;
    const { PreviewTabDispatch } = context.actions;
    const [property_data, setPropertyData] = useState('');

    

    useEffect(()=>{
        setPropertyData(props.property_data);
    },[props.property_data])

    const navigateLink = (e,tab_name, prev_tab, next_tab) => {
        //console.log('link---', link)
        //navigate(link);
        if(isBrowser()){
            window.scrollTo(0, 0);
        }        
        PreviewTabDispatch({current_tab:tab_name, prev_tab:prev_tab, next_tab:next_tab});
    }

    var numberOfCards = width > 799 && width < 1023 ? 5 : 2;
    //console.log("numberOfCards", numberOfCards, width);


    return(
    <>
    
        {
            props.PresentType === "preval" ? (
            <ItemsCarousel
            infiniteLoop={false}
            gutter={30}
            activePosition={'center'}
            chevronWidth={60}
            disableSwipe={true}
            alwaysShowChevrons={false}
            numberOfCards={numberOfCards}
            slidesToScroll={1}
            outsideChevron={true}
            showSlither={false}
            firstAndLastGutter={false}
            activeItemIndex={activeItemIndex}
            requestToChangeActive={value => setActiveItemIndex(value)}
            rightChevron={<Button shape="circle" variant="default"><img src={rightArw} alt="Next" title="" /></Button>}
            leftChevron={<Button shape="circle" variant="default"><img src={leftArw} alt="Prev" title="" /></Button>}
            >

                <div key={0} className="carousalItem">
                    <Nav.Link href={"javascript:;"} onClick={(e) => navigateLink(e, "Intro", 'first', "")} className={props.tab_name === "Intro" ? 'actv' : ''} >Intro</Nav.Link>
                </div>

                <div key={1} className="carousalItem">
                    <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Launch", "", "")} className={props.tab_name === "Launch" ? 'actv' : ''}>Launch</Nav.Link>
                </div>
                <div key={2} className="carousalItem">
                    <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Case Studies", "", "")} className={props.tab_name === "Case Studies" ? 'actv' : ''}>Case Studies</Nav.Link>
                </div>
                <div key={3} className="carousalItem">
                    <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "People", '', "")} className={props.tab_name === "People" ? 'actv' : ''}>People</Nav.Link>
                </div>
                <div key={4} className="carousalItem">
                    <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Marketing", '', "")} className={props.tab_name === "Marketing" ? 'actv' : ''}>Marketing</Nav.Link>
                </div>
                <div key={5} className="carousalItem">
                    <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Valuation Day", '', '')} className={props.tab_name === "Valuation Day" ? 'actv' : ''}>Valuation Day</Nav.Link>
                </div>
                <div key={6} className="carousalItem">
                    <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Thank You", '', 'last')} className={props.tab_name === "Thank You" ? 'actv' : ''}>Thank You</Nav.Link> 
                </div>   
            </ItemsCarousel>                   
            ) : (
            <ItemsCarousel
                infiniteLoop={false}
                gutter={30}
                activePosition={'center'}
                chevronWidth={60}
                disableSwipe={true}
                alwaysShowChevrons={false}
                numberOfCards={numberOfCards}
                slidesToScroll={1}
                outsideChevron={true}
                showSlither={false}
                firstAndLastGutter={false}
                activeItemIndex={activeItemIndex}
                requestToChangeActive={value => setActiveItemIndex(value)}
                rightChevron={<Button shape="circle" variant="default"><img src={rightArw} alt="Next" title="" /></Button>}
                leftChevron={<Button shape="circle" variant="default"><img src={leftArw} alt="Prev" title="" /></Button>}
                >   
                    {props.PresentType !== "custom_present" && 
                    <div key={0} className="carousalItem">
                        <Nav.Link href={"javascript:;"} onClick={(e) => navigateLink(e, "Intro", 'first', "")} className={props.tab_name === "Intro" ? 'actv' : ''} >Intro</Nav.Link>
                    </div>}
                    {props.PresentType !== "custom_present" &&
                    <div key={0} className="carousalItem">
                        <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Case Studies", "", "")} className={props.tab_name === "Case Studies" ? 'actv' : ''}>Case Studies</Nav.Link>
                    </div>}

                    {props.PresentType !== "custom_present" &&
                    <div key={0} className="carousalItem">
                        <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Launch", "", "")} className={props.tab_name === "Launch" ? 'actv' : ''}>Launch</Nav.Link>
                    </div>}

                    {props.PresentType !== "custom_present" && props.PresentType === "custom_agent" &&
                    <div key={0} className="carousalItem">
                        <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Schedule", "", "")} className={props.tab_name === "Schedule" ? 'actv' : ''}>Schedule</Nav.Link>
                    </div>}

                    {props.PresentType !== "custom_present" && (property_data && parseInt(property_data.price_expectancy) > 200000 && <>
                        <div key={0} className="carousalItem">
                            <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Plan", '', "")} className={props.tab_name === "Plan" ? 'actv' : ''}>Plan</Nav.Link>
                        </div>
                        </>)
                    }

                    {props.PresentType !== "custom_present" &&
                    <div key={0} className="carousalItem">
                        <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Team", '', '')} className={props.tab_name === "Team" ? 'actv' : ''}>Team</Nav.Link>
                    </div>}

                    {/* {props.PresentType !== "custom_present" &&
                    <div key={0} className="carousalItem">
                        <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Photographer", '', '')} className={props.tab_name === "Photographer" ? 'actv' : ''}>Photographer</Nav.Link>
                    </div>} */}
                    
                    {props.PresentType !== "custom_present" &&
                    <div key={0} className="carousalItem">
                        <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Previewings", '', "")} className={props.tab_name === "Previewings" ? 'actv' : ''}>Buyers</Nav.Link>
                    </div>}
                    
                    {props.PresentType !== "custom_present" &&
                    <div key={0} className="carousalItem">
                        <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Marketing", '', "")} className={props.tab_name === "Marketing" ? 'actv' : ''}>Marketing</Nav.Link>
                    </div>}

                    {props.PresentType !== "custom_present" &&
                    <div key={0} className="carousalItem">
                        <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Comparables", '', "")} className={props.tab_name === "Comparables" ? 'actv' : ''}>Comparables</Nav.Link>
                    </div>}

                    { props.PresentType === "custom_present" && property_data.product_specifications && (
                        <div key={0} className="carousalItem">
                            <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Campaign", '', "")} className={props.tab_name === "Campaign" ? 'actv' : ''}>Campaign</Nav.Link>
                        </div>
                    )}

                    <div key={0} className="carousalItem">
                        <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Fees", '', "")} className={props.tab_name === "Fees" ? 'actv' : ''}>Fees</Nav.Link>
                    </div>

                    <div key={0} className="carousalItem">
                        <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Instruct", '', props.PresentType === "custom_client" ? "" : "last")} className={props.tab_name === "Instruct" ? 'actv' : ''}>Instruct</Nav.Link>
                    </div>

                    { props.PresentType === "custom_client" && 
                        <div key={0} className="carousalItem">
                            <Nav.Link href="javascript:;" onClick={(e) => navigateLink(e, "Thank You", '', 'last')} className={props.tab_name === "Thank You" ? 'actv' : ''}>Thank You</Nav.Link>
                        </div>
                    }
                </ItemsCarousel>
            )                    
        }

    </>
    )
}

export default Slider_NavLink