import React, {useEffect, useState}  from "react"
import dateFormat from 'dateformat';
import moment from 'moment';
import {Container,Navbar,Nav} from 'react-bootstrap';
import { Link, navigate } from "gatsby"

import VideoModule from "./video-module"

import {config} from '../../components/Common/constant';

import HTMLReactParser from 'html-react-parser';
import { GET_ARTICLE, SELLER_DETAILS_EMAIL } from "../../queries/common_use_query";
import { submitPresentationStatus } from "../../queries/api_links";
import { useContainerContext } from "../../store/ContainerContext";

import TimeTrackerComp from "./time_tracker";

const Preval_Intro = (props) => {

    var strapi_url = process.env.GATSBY_STRAPI_SRC, article_link="custom-presentation-intro";
    const context = useContainerContext();
    const { property_data } = context.state;

    const [ article_data, setArticleData ] = useState('');
    const [ logged_user, setloggedUser ] = useState('');
    const [ playVideo, setPlayVideo ] = useState(false);
    
    if(props.PresentType === "custom_client"){
        article_link = "custom-client-intro";
    }
    const { loading, error, data } = GET_ARTICLE(article_link);

    useEffect(()=>{
        setArticleData(data && data.articles[0]);
    }, [data]);

    useEffect(()=>{
        setloggedUser(props.logged_user);
    }, [props.logged_user]);

    const sendStatus = (present_type, present_status, next_link) => {
        //console.log("property_data",property_data);
        // var get_property_id = property_data && property_data.id;
        // const updateQry = submitPresentationStatus(get_property_id, present_type, present_status, property_data && property_data).then(res => {
        //   if(res && res.status === 200){
        //     //console.log("present => res", res);
        //     navigate(next_link)
        //   } else{

        //   }
        // });
        navigate(next_link);
    }
    //console.log("testing..");
  
    return <>
        <TimeTrackerComp TabName={'Intro'} />

        <div className={`contact-wrapper intro_section `}>
          <Container>
            <div className="contact-wrap">
                <div className="contact-content">
                    <h2>{article_data && article_data.Modules[0].Title}</h2>
                    
                    <p class="fig-content">{article_data && HTMLReactParser(article_data.Modules[0].Content)}</p>

                    {
                        props.Type === "preview" ? ('') : (
                            <>
                                {article_data && article_data.Modules[0].CTA_1_Label && <a href="javascript:;" className="btn btn-primary" onClick={() => sendStatus('custom', 'Draft', article_data.Modules[0].CTA_1_URL ? article_data.Modules[0].CTA_1_URL : config.custom_marketing)}>{article_data.Modules[0].CTA_1_Label}</a> }

                                {article_data && article_data.Modules[0].CTA_2_Label && <Link to={article_data.Modules[0].CTA_2_URL ? article_data.Modules[0].CTA_2_URL : 'javascript:;'} className="btn btn-secondary">{article_data.Modules[0].CTA_2_Label}</Link> }
                            </>
                        )
                    }
                    
                </div>
                {/* contact-content */} 

                <div className="employment-right">
                    <div className={`video-wrap ${props.logged_user && (props.logged_user.photo) ? 'no_img' : ''}`}>
                    {props.PresentType === "custom_client" ? 
                        article_data && article_data.Modules[0] && article_data.Modules[0].Embed_Video_URL ? 
                            <VideoModule video_url={article_data.Modules[0].Embed_Video_URL} playing={true} loop={true} width={"100%"} height={"480px"} /> 
                            : (
                            <span className="coming_soon">Video Coming Soon</span>
                        ) : 
                        props.logged_user && (props.logged_user.photo) ? (
                            <figure>
                                {/* { logged_user && logged_user.logged_user_data && <img src={logged_user.logged_user_data.photo} alt="" /> } */}
                                { props.logged_user && <div className="introImg" style={{backgroundImage:`url("${props.logged_user.photo}")`}}>
                                </div> }
                            </figure>
                            ) : (
                            <span className="coming_soon">Image Coming Soon</span>                                            
                        )
                    }
                    </div>
                </div>
                {/* video-wrap */}
            </div>
            {/* contact-wrap */}
          </Container>                     
      </div>
      {/* contact-wrapper */}
    </>
}

export default Preval_Intro
