import React, {useEffect, useState, useRef} from "react"
import { Link, navigate } from "gatsby"
import {Container,Table, Dropdown, Row, Col, Button, Form} from 'react-bootstrap';
import SEO from "../components/seo"
import "../assests/scss/print.scss";
import axios from 'axios';
import * as qs from "query-string"
import moment from 'moment';
import dateFormat from 'dateformat';

import HTMLReactParser from 'html-react-parser';
import { PROPERTY_DETAILS, PROPERTY_PREVIEW_DETAILS, GET_ARTICLE } from "../queries/common_use_query";
import Preview_Header, {userDetailsCallback} from  "../components/Common/Preview_Header";
import NavLink from  "../components/NavLink/Preview_Presentation"; 

import download_icon from "../assests/images/download_icon.svg";

import {isLoggedIn, isBrowser, logout, getUser } from "../services/auth"

import PrevalIntro from  "../components/modules/preval_intro";
import ThankYou from  "../components/modules/thank_you";
import PropertyDetails from  "../components/preview/property_details";
import SellerDetails from  "../components/preview/seller_details";
import PropertyLaunch from  "../components/modules/property_launch";
import CaseStudies from  "../components/modules/sell-with-confidence";
import Teams from  "../components/modules/teams";
import Photographer from  "../components/modules/360-photographer";
import AdvanceBuyer from  "../components/modules/advance_buyer";
import DigitalDomination from  "../components/modules/digital_domination";
import CampaignPlan from  "../components/modules/campaign_plan";
import CampaignReport from  "../components/modules/campaign_report";
import Comparables from  "../components/modules/comparables";
import Preview_Payment from  "../components/preview/payment";
import Payment from  "../components/modules/payment";
import CompareCost from  "../components/modules/compare_cost";
import ValuationDay from  "../components/modules/valuation_day";
import MarketingAnalytics from  "../components/modules/marketing_analytics";
import WorldclassPhotography from  "../components/modules/worldclass_photography";
import People from  "../components/modules/people";
import Your_Home from  "../components/modules/your_home";
import Demand_Your_Home from  "../components/modules/demand_your_home";
import MarketingCampaign from  "../components/modules/marketing_campaign";
import ContractSubmission from  "../components/preview/contract_submission";

import Property_Details from  "../components/modules/property_details";
import PhotoGallery from  "../components/modules/photo_gallery";
import Seller_Details from  "../components/modules/seller_details";
import Seller_Fact_Find from  "../components/modules/seller_fact_find_preview";
import Custom_Intro from  "../components/modules/custom_intro";
import Property_Launch_DateTime from  "../components/modules/property_date_time";
import Photographer_DateTime from  "../components/modules/photographer_date_time";

import ContractForm from  "../components/modules/contract_form";
import ProductMix from  "../components/modules/product-mix";

// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';

import TimeTrackerComp from "../components/modules/time_tracker";
import {uniqueID, numberWithCommas, numberOnly, removeNonNumeric} from '../components/utils/common';

import { useContainerContext } from "../store/ContainerContext";
import ReactToPrint, {useReactToPrint} from 'react-to-print';

import { UserTracking } from "../queries/api_links";

//import {DeviceUUID} from 'device-uuid';

import $  from 'jquery';

const PresentationPreview = (props) => {

    var property_date_json = {
        "Launches": [
            {
          "PhotographyAppointments": [
            {
              "PhotographyStart": "2021-07-14T08:00:00.000Z",
              "PhotographyEnd": "2021-07-14T09:30:00.000Z",
              "Photographer": "propertybase@prestonbaker.co.uk"
            },
            {
              "PhotographyStart": "2021-07-14T09:30:00.000Z",
              "PhotographyEnd": "2021-07-14T11:00:00.000Z",
              "Photographer": "propertybase@prestonbaker.co.uk"
            },
            {
              "PhotographyStart": "2021-07-14T11:00:00.000Z",
              "PhotographyEnd": "2021-07-14T12:30:00.000Z",
              "Photographer": "propertybase@prestonbaker.co.uk"
            }
          ],
          "LaunchStart": "2021-07-17T08:30:00.000Z",
          "LaunchEnd": "2021-07-17T12:00:00.000Z",
          "CSM": "alex.renardson@prestonbaker.co.uk"
        },
        {
          "PhotographyAppointments": [
            {
              "PhotographyStart": "2021-07-14T08:00:00.000Z",
              "PhotographyEnd": "2021-07-14T09:30:00.000Z",
              "Photographer": "propertybase@prestonbaker.co.uk"
            },
            {
              "PhotographyStart": "2021-07-14T09:30:00.000Z",
              "PhotographyEnd": "2021-07-14T11:00:00.000Z",
              "Photographer": "propertybase@prestonbaker.co.uk"
            },
            {
              "PhotographyStart": "2021-07-14T11:00:00.000Z",
              "PhotographyEnd": "2021-07-14T12:30:00.000Z",
              "Photographer": "propertybase@prestonbaker.co.uk"
            }
          ],
          "LaunchStart": "2021-07-18T13:00:00.000Z",
          "LaunchEnd": "2021-07-18T16:30:00.000Z",
          "CSM": "alex.renardson@prestonbaker.co.uk"
        }
      ]    
    }

    var site_url = process.env.GATSBY_SITE_URL;

    const context = useContainerContext();
    const { logged_user_data, property_data, preview_tab, preview_sub_page, property_date_time, property_launch_date_time, photographer_date_time, user_tracking } = context.state;

    const { PreviewTabDispatch, PresentTypeDispatch, PropertyDateTimeDispatch, PropertyLaunchDateTimeDispatch, PhotographerDateTimeDispatch, UserTrackingDispatch } = context.actions;

    let path_name = (props.location.pathname).split('/');
    let get_url_ID = path_name[path_name.length-1];
    let get_property_ID = get_url_ID.split('-');

    var preview_url = site_url+props.location.pathname;

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    //console.log("get_property_ID", get_property_ID)

    const [ propertyDateTime, setPropertyDateTime ] = useState([]);
    const [ photoDateTime, setPhotoDateTime ] = useState([]);
    const [PreviewData, setPreviewData] = useState([]);
    const [PresentType, setPresentType] = useState('');
    const [PreviewPwd, setPreviewPwd] = useState('');
    const [password_check, setPasswordCheck] = useState(false);
    const [password_error, setPasswordError] = useState('');
    const [activeTab, setActiveTab] = useState('Seller Details');
    const [activeSubPage, setActiveSubPage] = useState('');
    const [marketing_price, setMarketingPrice] = useState(0);
    const [ userTrackDelay, setUserTrackDelay ] = useState(false);

    const [user_det_child, setUserDet] = useState(logged_user_data);

    //const { loading, error, data } = SELLER_DETAILS(json_parse.seller_email);
    const { loading:previewloading, error:previewerror, data:previewdata } = PROPERTY_DETAILS(get_property_ID[1]);

    const [ article_data, setArticleData ] = useState('');
    const { loading:article_loading, error:article_error, data:articledata } = GET_ARTICLE('presentation-preview');


    useEffect(() => {
        if(preview_tab && preview_tab.current_tab !== ''){
            setActiveTab(preview_tab.current_tab);
            
            if(preview_tab.current_tab === "Property Details" || preview_tab.current_tab === "Seller Details"){
                setActiveSubPage(preview_tab.current_tab)
            } else{
                setActiveSubPage('')
            }
            
        }
    },[preview_tab]);

    useEffect(() => {
        if(preview_sub_page === "Marketing" || preview_sub_page === "Team" || preview_sub_page === "Fees" || preview_sub_page === "Schedule"){
            setActiveSubPage('');
        } else{
            setActiveSubPage(preview_sub_page);
        }
        
    },[preview_sub_page]);

    useEffect(()=>{
        setArticleData(articledata && articledata.articles[0]);
    }, [articledata]);

    useEffect(() => {
        previewdata && previewdata.properties.length > 0 && setPreviewData(previewdata.properties[0]);
    },[previewdata]);

    useEffect(() => {
        //console.log("PreviewData", PreviewData);

        if(PreviewData) {
            //setPropertyDet(PreviewData);

            var preview_pwd = '', present_type = '', present_title = '', user_unique_id = '';

            var custom_preval_status = PreviewData.custom_and_pre_valution_presentation;

            var appointment_id = PreviewData.appointment && PreviewData.appointment.Id;
            user_unique_id = uniqueID();

            // if(isBrowser()){
            //     user_unique_id = new DeviceUUID().get();
            // } else{
            //     user_unique_id = uniqueID();
            // }

            // var preval_preview_url = PreviewData.preval_presentation_url && PreviewData.preval_presentation_url.URL;
            // var cust_client_preview_url = PreviewData.custom_presentation_client_url && PreviewData.custom_presentation_client_url.URL;
            // var cust_agent_preview_url = PreviewData.custom_presentation_agent_url && PreviewData.custom_presentation_agent_url.URL;
            // var cust_present_preview_url = PreviewData.custom_presentation_preview_url && PreviewData.custom_presentation_preview_url.URL;

            var preval_preview_url = PreviewData.urls && PreviewData.urls.PrevalPresentationURL;
            var cust_client_preview_url = PreviewData.urls && PreviewData.urls.CustomPresentationClientURL;
            var cust_agent_preview_url = PreviewData.urls && PreviewData.urls.CustomPresentationAgentURL;
            var cust_present_preview_url = PreviewData.urls && PreviewData.urls.CustomPresentationContractURL;

            if(preval_preview_url === preview_url){
                //preview_pwd = PreviewData.preval_presentation_url && PreviewData.preval_presentation_url.Password;
                present_type = "preval";
                present_title = "Pre-val Presentation";
            }

            if(cust_client_preview_url === preview_url){
                //preview_pwd = PreviewData.custom_presentation_client_url && PreviewData.custom_presentation_client_url.Password;
                present_type = "custom_client";
                present_title = "Custom Client Presentation";
            }

            if(cust_agent_preview_url === preview_url){
                //preview_pwd = PreviewData.custom_presentation_agent_url && PreviewData.custom_presentation_agent_url.Password;
                present_type = "custom_agent";
                present_title = "Custom Agent Presentation";
            }

            if(cust_present_preview_url === preview_url){
                //preview_pwd = PreviewData.custom_presentation_agent_url && PreviewData.custom_presentation_agent_url.Password;
                present_type = "custom_present";
                present_title = "Custom Contract Presentation";
            }

            // if(PreviewData.sign_in_agreement && PreviewData.sign_in_agreement.PaymentOption) {
            //     setMarketingPrice(PreviewData.sign_in_agreement.PaymentOption.MarketingPrice);
            // }

            if(PreviewData.sign_in_agreement && PreviewData.sign_in_agreement.PaymentOption && PreviewData.sign_in_agreement.PaymentOption.MarketingPrice) {
                console.log("test 1");
                setMarketingPrice(PreviewData.sign_in_agreement.PaymentOption.MarketingPrice);
             } else{
                console.log("test 2");
                if(PreviewData.seller_details && PreviewData.seller_details.RecommendedMPrice) {
                    console.log("test 3");
                    setMarketingPrice(PreviewData.seller_details.RecommendedMPrice);
                }
             }

            //setPreviewPwd(preview_pwd);
            setPresentType(present_type);
            PresentTypeDispatch(present_type);

            UserTrackingDispatch({...user_tracking, UserId: user_unique_id, AppoinmentId: appointment_id, PresentationType: present_title, PresentationUrl: preview_url});

            if(isBrowser()){
                const compare_pwd = preview_pwd.localeCompare(localStorage.getItem("preview_password"), undefined);

                //console.log('check_pwd', compare_pwd, localStorage.getItem("preview_password"), preview_pwd);
                if(compare_pwd === 0){
                    setPasswordCheck(true);
                } else{
                    setPasswordCheck(false);
                } 
            }            
        }
    },[PreviewData]);

    useEffect(() => {
        setUserDet(logged_user_data);        
    },[logged_user_data]);

    useEffect(()=>{
        if(PresentType ==="preval"){
            PropertyLaunchDateTimeDispatch({});
            PhotographerDateTimeDispatch({})
        } else if((PreviewData && PreviewData.appointment) && PresentType ==="custom_agent"){
            const axiosOptions_URL = {
              url: '/.netlify/functions/server/property-date-time',
              //url: '/server/property-date-time',
              method: "post",
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
              data: qs.stringify({id:PreviewData.appointment.Id}),
            }
    
            const PropDateTime = axios(axiosOptions_URL).then((result) => {
              //console.log("result", result);
              if(result.status === 200){
                PropertyDateTimeDispatch(result.data);
                //PropertyDateTimeDispatch(property_date_json);
              } else{
                //PropertyDateTimeDispatch(property_date_json);
              }
            }).catch((err) => {
              //PropertyDateTimeDispatch(property_date_json);
              //console.log("err", err)
            })
        } else{
            // PropertyLaunchDateTimeDispatch({"LaunchStart": "2021-07-17T08:30:00.000Z", "CSM": "angela.williams@prestonbaker.co.uk", "PhotographyAppointments": [{"PhotographyStart": "2021-07-14T08:00:00.000Z", "Photographer": "andrew.mcgregor@prestonbaker.co.uk"}] });
            // PhotographerDateTimeDispatch({ "PhotographyStart": "2021-07-14T08:00:00.000Z", "Photographer": "andrew.mcgregor@prestonbaker.co.uk"})
        }    
        //PropertyDateTimeDispatch(property_date_json);
    },[PresentType]);

    useEffect(() => {
        var calendarEvents = [];
        property_date_time.Launches && property_date_time.Launches.length > 0 && property_date_time.Launches.map((item,index) => {

            var csm_initials = item.CSM.split('.');
            var LaunchStart= moment(item.LaunchStart).add(1, 'hour').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

            calendarEvents.push({title: csm_initials[0].substr(0,1)+''+csm_initials[1].substr(0,1), date: LaunchStart, start: LaunchStart, PhotographyAppointments: item.PhotographyAppointments, LaunchEnd: item.LaunchEnd, LaunchStart: LaunchStart, CSM:item.CSM})
        })
        setPropertyDateTime(calendarEvents);
    }, [property_date_time]);

    useEffect(()=>{
        var calendarEvents = [];

        property_launch_date_time && property_launch_date_time.PhotographyAppointments && property_launch_date_time.PhotographyAppointments.length > 0 && property_launch_date_time.PhotographyAppointments.map((item,index) => {

            var PhotographyStart = moment(item.PhotographyStart).add(1, 'hour').utc().format('YYYY-MM-DDTHH:mm:ss.SSSS[Z]');
            var PhotographyEnd = moment(item.PhotographyEnd).add(1, 'hour').utc().format('YYYY-MM-DDTHH:mm:ss.SSSS[Z]');

            calendarEvents.push({title: item.Photographer, date: PhotographyStart, start: PhotographyStart, PhotographyStart: PhotographyStart, PhotographyEnd: PhotographyEnd, Photographer:item.Photographer})
        })
        setPhotoDateTime(calendarEvents);
    }, [property_launch_date_time]);

    useEffect(() => {
        var user_tracking_var = user_tracking;
        window.onbeforeunload = confirmExit;
        function confirmExit(event)
        {
            event.preventDefault();
            //console.log("user_tracking", user_tracking_test)
            var tab_name = props.TabName, post_data = {};
            post_data = user_tracking_var;
            //console.log("post_data", post_data, Object.keys(post_data.PageViews).length)
            if(Object.keys(post_data.PageViews).length > 0){
                var res = UserTracking(post_data).then(res => {
                console.log("user_tracking_res", res)
                if(res && res.status === 200){
                    //UserTrackingDispatch({...user_tracking, PageViews:{}});
                }
                });
            }
            
            return "";
        }
    }, [user_tracking]);

    useEffect(() => {
        const timer = setTimeout(() => {
          console.log('setTimeout called!');
          setUserTrackDelay(true);
        }, 600000);
      
        return () => clearTimeout(timer);
    }, []);

    if(userTrackDelay){
        setUserTrackDelay(false);
        var post_data = user_tracking;
        if(Object.keys(post_data.PageViews).length > 0){
            var res = UserTracking(post_data).then(res => {
                console.log("user_tracking_res", res)
                if(res && res.status === 200){
                    //UserTrackingDispatch({...user_tracking, PageViews:{}});
                }
            });
        }
    }

    const recommendedPrice = (marketing_price) =>{
        // let value = event.target.value; 
        // let name = event.target.name; 
        let value = marketing_price;   
  
        setMarketingPrice(value > 0 ? value : 0);
    }


    const handleSubmit = (e, url_pwd) => {
         //console.log("check_pwd", document.getElementById("preview_url_password").value, url_pwd)
         const compare_pwd = url_pwd.localeCompare(document.getElementById("preview_url_password").value, undefined);

         //console.log("compare_pwd", compare_pwd, url_pwd, document.getElementById("preview_url_password").value);
         if(compare_pwd === 0){
            if(isBrowser()){
                localStorage.setItem("preview_password", document.getElementById("preview_url_password").value);
            }
            setPasswordCheck(true)
         } else{
            setPasswordError('Password is incorrect!')
            setPasswordCheck(false)
         }
    }
    //console.log("PreviewData", PreviewData);

   
    

    var propertyDateTime_Preview = [], photoDateTime_Preview = [];
    
    if(PreviewData && PreviewData.sign_in_agreement && PreviewData.sign_in_agreement.CustomPresentation && PreviewData.sign_in_agreement.CustomPresentation.PropertyLaunchDateTime){
        var property_launch_date_time_preview = PreviewData && PreviewData.sign_in_agreement && PreviewData.sign_in_agreement.CustomPresentation && PreviewData.sign_in_agreement.CustomPresentation.PropertyLaunchDateTime, 

        property_launch_email = PreviewData && PreviewData.sign_in_agreement && PreviewData.sign_in_agreement.CustomPresentation && PreviewData.sign_in_agreement.CustomPresentation.PropertyLaunchUser, 

        photographer_date_time_preview = PreviewData && PreviewData.sign_in_agreement && PreviewData.sign_in_agreement.CustomPresentation && PreviewData.sign_in_agreement.CustomPresentation.PhotographyDateAndTime360, 

        photographer_email = PreviewData && PreviewData.sign_in_agreement && PreviewData.sign_in_agreement.CustomPresentation && PreviewData.sign_in_agreement.CustomPresentation.PhotographyUser360;

        if(property_launch_email){
            var csm_initials2 = property_launch_email && property_launch_email.split(' ');
            propertyDateTime_Preview = [{title: csm_initials2[0].substr(0,1)+''+csm_initials2[1].substr(0,1), date: property_launch_date_time_preview, start: property_launch_date_time_preview, description:''}];
        }
        
        if(photographer_email){
            photoDateTime_Preview = [{title: photographer_email, date: photographer_date_time_preview, start: photographer_date_time_preview, description:''}];
        }
        
    }
    

    var property_launch_datetime =  PresentType === "custom_agent" ? (propertyDateTime && propertyDateTime.length > 0 ? propertyDateTime : []) :  propertyDateTime_Preview;

    //console.log("previewdata", PreviewData, previewdata && previewdata.properties);
    //console.log("PresentType", PresentType, property_launch_datetime);
    //console.log("user_tracking", user_tracking);

    //console.log("property_launch_date_time_preview", property_launch_date_time_preview, moment(property_launch_date_time_preview).add(1, 'hour').utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'));

    return <>
    <TimeTrackerComp />

    <SEO title="Presentation Preview" description="Presentation Preview" />

    <div className={`pre-wrapper ${activeTab === "Case Studies" ? 'pb-3' : ''}`}>

        {
            previewloading ? ('') : (
            previewdata && previewdata.properties.length > 0 ? ( 
            PreviewData && PreviewData.urls && (
                PresentType != '' ? (
                    // password_check ? (
                    <>
                        {PreviewData && PreviewData.user_email && <Preview_Header user_email={PreviewData.user_email}/> }

                        <div className="section-wrapper pt-0">

                                {/* {
                                    (activeTab === "Seller Details") ? (
                                        // <h2 className="preview_header">{PresentType === "custom_agent" ? "Custom Presentation Agent Preview" : PresentType === "custom_client" ? "Custom Presentation Client Preview" : "Preval Presentation Preview" }
                                        // </h2>
                                        <h2 className="preview_header font_28">{PreviewData.seller_name}</h2>
                                    ) : (
                                        activeTab === "Property Details" ? (
                                            <h2 className="preview_header font_28">{PreviewData.address}</h2>
                                        ) : ('')
                                    )
                                    
                                }                                 */}

                                {
                                    activeTab === "Intro" && (
                                    <div id="pdf_generate" className="previewPage" ref={componentRef}>
                                        <div className="preview-wrap">
                                            <Container>
                                            <Row className={`property_launch_prev intro_wrap`}>
                                                {(PreviewData && PresentType === "preval") ? (
                                                    <PrevalIntro Type="preview" property_data = {PreviewData} logged_user={user_det_child} seller_email={PreviewData && PreviewData.seller_email} />
                                                ) : (
                                                    <Custom_Intro Type="preview" PresentType={PresentType} logged_user={user_det_child} />
                                                ) }
                                            </Row>
                                            </Container>       
                                            {/* {
                                                activeTab === "Seller Details" &&
                                                <div className={`sell_details`}>
                                                    <div className="seller-tabs mt-0">
                                                        <ul>
                                                            <li><a href="javascript:;" className={`tab ${activeSubPage === "Seller Details" ? 'actv' : ''}`} onClick={() =>setActiveSubPage("Seller Details")}>Seller Details</a></li>
                                                            <li><a href="javascript:;" className={`tab ${activeSubPage === "Seller Fact Find" ? 'actv' : ''}`} onClick={() => setActiveSubPage("Seller Fact Find")}>Seller Fact Find</a></li>  
                                                        </ul>
                                                    </div>

                                                    {activeSubPage === "Seller Details" && <Seller_Details seller_email={PreviewData && PreviewData.seller_email} property_data = {PreviewData} Type="preview" /> }

                                                    {activeSubPage === "Seller Fact Find" && <div className="sell_fact_find"><Seller_Fact_Find seller_fact_find={ PreviewData.seller_fact_find} Type="preview" /></div> }
                                                </div>
                                            }                                        

                                            {activeTab === "Property Details" && 
                                            <div className={`sell_details`}>
                                                <div className="seller-tabs mt-0">
                                                    <ul>
                                                        <li><a href="javascript:;" className={`tab ${activeSubPage === "Property Details" ? 'actv' : ''}`} onClick={() =>setActiveSubPage("Property Details")}>Property Details</a></li>
                                                        <li><a href="javascript:;" className={`tab ${activeSubPage === "Photo Gallery" ? 'actv' : ''}`} onClick={() => setActiveSubPage("Photo Gallery")}>Photo Gallery</a></li>  
                                                    </ul>
                                                </div>
                                                {activeSubPage === "Property Details" && <Property_Details property_data = {PreviewData} Type="preview" /> }

                                                {activeSubPage === "Photo Gallery" && <div className="card-block"><PhotoGallery /></div>}                            
                                                                        
                                            </div>
                                            } */}
                                            {/* <CaseStudies property_data = {PreviewData} /> */}
                                        </div>
                                    </div>
                                    )
                                }
                                
                                {activeTab === "Case Studies" && 
                                    <div id="pdf_generate" className={`previewPage`} ref={componentRef}>
                                        <div className="preview-wrap">                                           
                                            <Row className="property_launch_prev">
                                                <Col lg={12} md={12}>
                                                    <div className="card-block">
                                                        {PreviewData && <CaseStudies Type="preview" property_data = {PreviewData} height="650px"  /> }
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                }


                                <div id="pdf_generate" className="previewPage" ref={componentRef}>
                                    <div className="preview-wrap">
                                        {
                                            PreviewData && PresentType === "preval" && (
                                                activeTab === "People" && (
                                                <Row className={`property_launch_prev`}>
                                                    <Col lg={12} md={12}>
                                                        <div className="card-block">
                                                            <People Type="preview" user_data={user_det_child} property_data = {PreviewData} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                )
                                            )
                                        }
                                        
                                        {/* {activeTab === "Marketing" && (
                                            <Row className={`property_launch_prev tabCnt`}>
                                                <Col lg={12} md={12}>
                                                <Container>                                                
                                                    <div className={`sell_details`}>
                                                        <div className="seller-tabs mt-0">
                                                            <ul>
                                                                <li><a href="javascript:;" className={`tab ${activeSubPage === "" ? 'actv' : ''}`} onClick={() =>setActiveSubPage("")}>Property Launch</a></li>
                                                                 <li><a href="javascript:;" className={`tab ${activeSubPage === "World Class" ? 'actv' : ''}`} onClick={() => setActiveSubPage("World Class")}>Photography</a></li>
                                                                <li><a href="javascript:;" className={`tab ${activeSubPage === "Digital Domination" ? 'actv' : ''}`} onClick={() => setActiveSubPage("Digital Domination")}>Digital Domination</a></li>  

                                                                {PreviewData && PresentType !== "preval" &&
                                                                    PreviewData.campaign_report_datas && PreviewData.campaign_report_datas.length > 0 && (
                                                                        <li><a href="javascript:;" className={`tab ${activeSubPage === "Campaign Report" ? 'actv' : ''}`} onClick={() => setActiveSubPage("Campaign Report")}>Campaign Report Data</a></li>
                                                                    )
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>                                                   
                                                </Container>
                                                </Col>
                                            </Row>
                                        )} */}
                                        
                                        {(activeTab === "Launch") && 
                                        <Row className={`property_launch_prev`}>
                                            <Col lg={12} md={12}>
                                                <div className="card-block">
                                                    <PropertyLaunch Type="preview" NextPage={""} PrevPage={""} />

                                                    <MarketingAnalytics Type="preview" NextPage={""} PrevPage={""} />
                                                </div>
                                            </Col>
                                        </Row>
                                        }

                                        {/* {activeSubPage === "Marketing Analytics" && 
                                        <Row className={`property_launch_prev`}>
                                            <Col lg={12} md={12}>
                                                <div className="card-block">
                                                    <MarketingAnalytics Type="preview" NextPage={""} PrevPage={""} />
                                                </div>
                                            </Col>
                                        </Row>
                                        } */}
                                    
                                        {/* {activeSubPage === "Digital Domination" && 
                                        <Row className={`property_launch_prev`}>
                                            <Col lg={12} md={12}>
                                                <div className="card-block">
                                                    <WorldclassPhotography Type="preview" NextPage={""} PrevPage={""} />
                                                </div>
                                            </Col>
                                        </Row> 
                                        } */}

                                        {activeTab === "Marketing" &&
                                        <Row className={`property_launch_prev digital_domination`}>
                                            <Col lg={12} md={12}>
                                                <div className="card-block">
                                                    <DigitalDomination Title="" Type="preview" presentation = {PreviewData &&  (PresentType).toLowerCase()} NextPage={""} PrevPage={""} />
                                                </div>
                                            </Col>
                                        </Row>
                                        }

                                        

                                        {PreviewData && PresentType === "preval" ? (
                                            <>
                                                {activeTab === "Marketing" && 
                                                    <Row className={`property_launch_prev`}>
                                                        <Col lg={12} md={12}>
                                                            <div className="card-block">
                                                                <WorldclassPhotography Type="preview" property_data = {PreviewData} NextPage={""} PrevPage={""} />
                                                            </div>
                                                        </Col>
                                                    </Row> 
                                                }

                                                {/* {activeSubPage === "Your Home" && 
                                                <Row className={`property_launch_prev`}>
                                                    <Col lg={12} md={12}>
                                                        <div className="card-block">
                                                            <Your_Home Type="preview" NextPage={""} PrevPage={"Digital Domination"} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                } */}

                                                {/* {activeSubPage === "Demand Your Home" && 
                                                <Row className={`property_launch_prev`}>
                                                    <Col lg={12} md={12}>
                                                        <div className="card-block">
                                                            <Demand_Your_Home Type="preview" NextPage={""} PrevPage={"Your Home"} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                } */}

                                                {activeTab === "Valuation Day" &&
                                                    <Row className={`property_launch_prev`}>
                                                        <Col lg={12} md={12}>
                                                            <div className="card-block">
                                                                <ValuationDay Title="" Type="preview"/>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                }
                                            </>
                                            ) :  (
                                            <>

                                            {activeTab === "Marketing" && 
                                                PreviewData.campaign_report_datas && PreviewData.campaign_report_datas.length > 0 && (
                                                    <Row className="property_launch_prev">
                                                        <Col lg={12} md={12}>
                                                            <div className="card-block">
                                                                <CampaignReport Title="" Type="preview" NextPage={""} PrevPage={""} property_data = {PreviewData}  />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )
                                            }

                                            {activeTab === "Marketing" &&  PresentType === "custom_agent" && 
                                                PreviewData && PreviewData.product_specifications && Object.keys(PreviewData.product_specifications).filter(key => PreviewData.product_specifications[key] === true).length > 0 && (
                                                    <Row className="property_launch_prev">
                                                        <Col lg={12} md={12}>
                                                            <div className="card-block">
                                                                <ProductMix Title="" Type="preview" NextPage={""} PrevPage={""} property_data = {PreviewData}  />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )
                                            }

                                            {activeTab === "Schedule" && <>
                                                <Row className="property_launch_prev">
                                                    <Col lg={12} md={12}>
                                                        <div className="card-block">
                                                            <Property_Launch_DateTime Title="" Type={"preview"} present_type={PresentType} NextPage={"Photography"} PrevPage={""} propertyDateTime={property_launch_datetime} PreviewData={PreviewData} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            
                                                <Row className="property_launch_prev">
                                                    <Col lg={12} md={12}>
                                                        <div className="card-block">
                                                            <Photographer_DateTime Title="" Type={PresentType === "custom_agent" ? "" : "preview"} present_type={PresentType} NextPage={""} PrevPage={"Schedule"} photoDateTime={PresentType === "custom_agent" ? photoDateTime : photoDateTime_Preview} propertyDateTime={property_launch_datetime}  PreviewData={PreviewData}/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </>
                                            }                                            

                                            {activeTab === "Plan" &&
                                            <Row className="property_launch_prev">
                                                <Col lg={12} md={12}>
                                                    <div className="card-block">
                                                    <CampaignPlan Type="preview" property_data={PreviewData} property_launch_date_time={PresentType === "custom_agent" ? '' : property_launch_date_time_preview} property_launch_email={PresentType === "custom_agent" ? '' : property_launch_email} photographer_date_time={PresentType === "custom_agent" ? '' : photographer_date_time_preview} photographer_email={PresentType === "custom_agent" ? '' : photographer_email} 
                                                        PresentType={PresentType}/>
                                                    </div>
                                                </Col>
                                            </Row>
                                            }

                                            {activeTab === "Team" &&
                                            <Row className="property_launch_prev">
                                                <Col lg={12} md={12}>
                                                    <div className="card-block">
                                                        <Teams Title="Client Service Team" Type="preview" NextPage={""} PrevPage={""} property_data={PreviewData} />
                                                    </div>
                                                </Col>
                                            </Row>
                                            }

                                            {activeTab === "Team" && 
                                            <Row className="property_launch_prev">
                                                <Col lg={12} md={12}>
                                                    <div className="card-block">
                                                        <Photographer Title="Photographer" Type="preview" NextPage={''} PrevPage={""} property_data={PreviewData} />
                                                    </div>
                                                </Col>
                                            </Row>
                                            }

                                            {activeTab === "Previewings" &&
                                            <Row className="property_launch_prev">
                                                <Col lg={12} md={12}>
                                                    <div className="card-block">
                                                        <AdvanceBuyer Title="" Type="preview"  NextPage={""} PrevPage={""}  property_data={PreviewData}  />
                                                    </div>
                                                </Col>
                                            </Row>
                                            }

                                            {activeTab === "Comparables" &&
                                                <Row className="property_launch_prev">
                                                    <Col lg={12} md={12}>
                                                        <div className="card-block">
                                                            <Comparables Title="" Type="preview" property_data={PreviewData} map_height="400px" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }

                                            {
                                                activeTab === "Campaign" &&
                                                PreviewData && PreviewData.product_specifications && (
                                                    <Row className="property_launch_prev">
                                                        <Col lg={12} md={12}>
                                                            <div className="card-block">
                                                                <MarketingCampaign Title="" Type="preview" property_data={PreviewData}/>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )
                                            }
                   
                                            {(activeTab === "Fees") && 
                                                <Row className="property_launch_prev">
                                                    <Col lg={12} md={12}>
                                                        <div className="card-block">
                                                            {/* {
                                                                PresentType === "custom_agent" ? (
                                                                    <Payment Title="" Type="preview" present_type={PresentType} NextPage={"Compare Cost"} PrevPage={""} property_data={PreviewData}/>
                                                                ) : (
                                                                    <Preview_Payment Title="" Type="preview" present_type={PresentType} NextPage={"Compare Cost"} PrevPage={""} property_data={PreviewData}/>
                                                                )
                                                            } */}
                                                            <Payment Title="" Type="preview" present_type={PresentType} NextPage={"Compare Cost"} PrevPage={""} property_data={PreviewData} handleChange={recommendedPrice} marketing_price={marketing_price}/>
                                                            
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }

                                            {activeTab === "Fees" && 
                                                <Row className="property_launch_prev">
                                                    <Col lg={12} md={12}>
                                                        <div className="card-block no_bg">
                                                        {/* {
                                                            PresentType === "custom_agent" ? (
                                                                <CompareCost Title="" Type="" NextPage={""} PrevPage={"Fees"} property_data={PreviewData} marketing_price={marketing_price} />
                                                            ) : (
                                                                <CompareCost Title="" Type="preview" NextPage={""} PrevPage={"Fees"} property_data={PreviewData}/>
                                                            )
                                                        } */}
                                                        <CompareCost Title="" Type="" NextPage={""} PrevPage={"Fees"} property_data={PreviewData} marketing_price={marketing_price} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }

                                            {activeTab === "Instruct" &&
                                                <Row className="property_launch_prev">
                                                    <Col lg={12} md={12}>
                                                        <div className="card-block">
                                                            {/* <ContractSubmission Title="" Type="preview" property_data={PreviewData}/> */}
                                                            <ContractForm Title="" present_type={PresentType} Type="preview" property_data={PreviewData}/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }                                                
                                        </>
                                        )
                                    }


                                    {activeTab === "Thank You" && 
                                        <Row className={`property_launch_prev`}>
                                            <Col lg={12} md={12}>
                                                <ThankYou PresentType={PresentType} seller_email={PreviewData && PreviewData.seller_email} Type="preview" logged_user={user_det_child} />                                                
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                    // ) : (
                    //     <>
                    //         <Preview_Header user_email={PreviewData.user_email} />

                    //         <div className="section-wrapper">
                    //             <Container>
                    //                 <div className="form-wrapper text-center preview_form">
                    //                     {
                    //                         password_error && (
                    //                             <div className="col-md-12">
                    //                                 <div className="alert alert-danger">{password_error}</div>
                    //                             </div>
                                                
                    //                         )
                    //                     }
                    //                     <Form enctype="multipart/form-data"
                    //                         name={"preview_form"}
                    //                         action="javascript:;"
                    //                         method="post"
                    //                         onSubmit={(e) => handleSubmit(e, PreviewPwd)}>
                    //                         <div class="form-group col-md-12">
                    //                             <label>Submit password and view the presentation preview.</label>
                    //                             <input required name="preview_url_password" id="preview_url_password" placeholder="Enter password" type="password" class="form-control" />
                    //                         </div>
                    //                         <div class="col-md-undefined col-sm-12 col-xs-12"><button type="submit" class="btn-primary submitBtn btn">Submit</button></div>
                    //                     </Form>                                        
                    //                 </div>
                    //             </Container>
                    //         </div>
                    //     </>
                    // )
                    
                    )  : (
                        <>
                            <Preview_Header hide_right_side={true} user_email="" />
        
                            <div className="section-wrapper">
                                <Container>
                                    <div className="noRecMsg invalid">Invalid Presentation Preview URL!</div>
                                </Container>
                            </div>
                        </>
                    )
                )
                
            ) : (
                <>
                    <Preview_Header hide_right_side={true} user_email="" />

                    <div className="section-wrapper">
                        <Container>
                            <div className="noRecMsg invalid">Invalid Presentation Preview URL!</div>
                        </Container>
                    </div>
                </>
            )
        
            )
        }
        
    </div>

    {PreviewData && ( 
        PresentType != '' && (
             <NavLink tab_name={activeTab} prevLink="" nextLink="" PresentType={PresentType} property_data={PreviewData} /> 
        )
    )}
    

    </>
}

export default PresentationPreview
