import React, { useEffect, Component, useState } from "react"
import { Link, navigate } from "gatsby";
import HTMLReactParser from 'html-react-parser';
import dateFormat from 'dateformat';
import $ from "jquery";

import DownArw from "../../assests/images/red_down_arw.svg";
import { SELLER_DETAILS_EMAIL } from "../../queries/common_use_query";

const SellerDetails = (props) => {

    const [property_data, setPropertyData] = useState('');
    const [seller_data, setSellerData] = useState('');

    //const { loading:sellerloading, error:sellererror, data:sellerdata } = SELLER_DETAILS_EMAIL(props.seller_email); 

    useEffect(()=>{
        setPropertyData(props.property_data);
        setSellerData(props.seller_data)
    }, [props.seller_data, props.property_data]);

    useEffect(() => {
        $(".red_down_arw").on('click', function(){
            console.log("arw_cls", $(this).hasClass('actv'))
            if($(this).hasClass('actv')){
                $('.collapse_div').slideUp('slow');
                $(this).parent().next('.collapse_div').slideUp('slow');
                $(this).removeClass('actv');
            } else{
                $('.collapse_div').slideUp('slow');
                $('.red_down_arw').removeClass('actv');
                $(this).parent().next('.collapse_div').slideToggle('slow');
                $(this).toggleClass('actv');
            }            
            
        });
        
    },[]);

    
    
    //   var seller_addr_repl = seller_data && seller_data.address && (seller_data.address).replace('System.Address[', '{"').replace(']', '"}').replace(/=/g, '":"').replace(/;/g, '", "');
    //   var seller_addr_repl2 = seller_addr_repl && seller_addr_repl.replace(', ""', '').replace(/\r\n/g, ' ');
    //   var seller_addr_json = seller_addr_repl2 && JSON.stringify(seller_addr_repl2.toString());
    //   var seller_addr_obj = seller_addr_json && JSON.parse(seller_addr_json);
    //   var seller_address = seller_addr_obj && JSON.parse(seller_addr_obj);
    
    var seller_address = seller_data && seller_data.address && seller_data.address.replace(/,/g, ', ');
    var notes_soft_facts = seller_data && seller_data.notes_soft_facts ? seller_data.notes_soft_facts.replace(/\r\n/g, '<br />') : '';
    var notes_forward_plans_notes = seller_data && seller_data.notes_forward_plans_notes ? seller_data.notes_forward_plans_notes.replace(/\r\n/g, '<br />') : '';
    var notes_other_notes = seller_data && seller_data.notes_other_notes ? seller_data.notes_other_notes.replace(/\r\n/g, '<br />') : '';
    
    //console.log("seller_data2", seller_data)
  return (
    <>
    { props.seller_data && <>
        <div className="card-block">
            <h3>Primary Contact:</h3>
            <ul className="mt-3">
                {seller_data.name && <li><strong>Name:</strong><br />{seller_data.name}</li> }
                {/* <li><strong>Address:</strong><br />{seller_address && seller_address.getStreet +', '+seller_address.getPostalCode}</li> */}
                {seller_address && <li><strong>Address:</strong><br />{seller_address}</li> }

                {seller_data.telephone && <li><strong>Contact Number:</strong><br /><Link to={`tel:${seller_data.telephone}`}>{seller_data.telephone}</Link></li> }
                
                {seller_data.email && <li><strong>Email Address:</strong><br /><Link to={`mailto:${seller_data.email}`}>{seller_data.email}</Link></li> }
            </ul>
        </div>
    </> }

        <div className="card-block">
            <h3>Secondary Contact <a href="javascript:;" className="red_down_arw" ><img src={DownArw} alt="" /></a></h3>

            <div className="collapse_div">
                { props.seller_data &&
                    <ul>
                        {seller_data.name && <li><strong>Name:</strong><br />{seller_data.name}</li>}
                        {seller_data.telephone && <li><strong>Contact Number:</strong><br /><Link to={`tel:${seller_data.telephone}`}>{seller_data.telephone}</Link></li> }
                        {seller_data.email && <li><strong>Email Address:</strong><br /><Link to={`mailto:${seller_data.email}`}>{seller_data.email}</Link></li>}
                    </ul>
                }
                
            </div>                                        
        </div>

    { props.seller_data && <>
        <div className="card-block">
            <h3>Pre-Viewings<a href="javascript:;" className="red_down_arw"><img src={DownArw} alt="" /></a></h3>

            <div className="collapse_div">
                {
                    property_data && property_data.previewings && property_data.previewings.length > 0 && (
                        <ul>
                            {
                                property_data.previewings.map((item, index) => {
                                    return(
                                        <li><b>{item.ListOfBuyers}</b> - {item.DateOfViewing && dateFormat(item.DateOfViewing, "dd/mm/yy") +" at "+ dateFormat(item.DateOfViewing, "UTC:h:MMtt")}</li>
                                    )
                                })
                            }
                            
                        </ul>
                    )
                }
                
            </div>                                        
        </div>

        <div className="card-block">
            <h3>Notes <a href="javascript:;" className="red_down_arw"><img src={DownArw} alt="" /></a></h3>    
            <div className="collapse_div">
                <ul>
                    {seller_data.notes_reaosnformoving && <li><strong>Reason for Moving:</strong><br />{seller_data.notes_reaosnformoving}</li> }
                    {notes_soft_facts && <li><strong>Soft Facts:</strong><br />{HTMLReactParser(notes_soft_facts)}</li> }
                    {notes_forward_plans_notes && <li><strong>Forward Plans Notes:</strong><br />{HTMLReactParser(notes_forward_plans_notes)}</li> }
                    {notes_other_notes && <li><strong>Other Notes:</strong><br />{HTMLReactParser(notes_other_notes)}</li>}
                </ul>
            </div>                                    
        </div>

        <div className="card-block">
            <h3>Source & Recommendation <a href="javascript:;" className="red_down_arw"><img src={DownArw} alt="" /></a></h3>  

            <div className="collapse_div">
                <ul>
                    {seller_data.source_recommendation_who_recommended_us && <li><strong>Who recommeded us?</strong><br />{seller_data.source_recommendation_who_recommended_us}</li>}

                    {seller_data.source_and_recommendation && <li><strong>Source:</strong><br />{seller_data.source_and_recommendation}</li>}
                </ul>
            </div>                                      
        </div>

        <div className="card-block">
            <h3>Motivation <a href="javascript:;" className="red_down_arw"><img src={DownArw} alt="" /></a></h3>    

            <div className="collapse_div">
                <ul>
                    {seller_data.motivation_mostimportantthingaboutthemove && <li><strong>Most important thing about the move</strong><br />{seller_data.motivation_mostimportantthingaboutthemove}</li> }

                    {seller_data.motivation_why && <li><strong>Why?</strong><br />{seller_data.motivation_why}</li>}
                    
                    {seller_data.motivation_age_group && <li><strong>Age Group</strong><br />{seller_data.motivation_age_group}</li>}
                </ul>
            </div>                                      
        </div>

        <div className="card-block">
            <h3>Other Agent Marketing Information <a href="javascript:;" className="red_down_arw"><img src={DownArw} alt="" /></a></h3> 

            <div className="collapse_div">
                <ul>
                    <li><strong>Currently on the Market</strong><br />{seller_data.other_agent_marketing_info_currentlyonthemarket}</li>
                    <li><strong>Listed with another agent?</strong><br />{seller_data.other_agent_marketing_info_listedwithanotheragent}</li>
                    {seller_data.other_agent_marketing_info_endofotheragentsagreement && <li><strong>End of Other Agent's Aggrement</strong><br />{seller_data.other_agent_marketing_info_endofotheragentsagreement}</li> }

                    <li><strong>Property marketd before?</strong><br />{seller_data.other_agent_marketing_info_propertymarketedbefore}</li>

                    {seller_data.other_agent_marketing_info_howlong && <li><strong>How long?</strong><br />{seller_data.other_agent_marketing_info_howlong}</li>}

                    {seller_data.other_agent_marketing_info_which_agent && <li><strong>Which Agent?</strong><br />{seller_data.other_agent_marketing_info_which_agent}</li>}

                    {seller_data.other_agent_marketing_info_interestlevel && <li><strong>Interest Level?</strong><br />{seller_data.other_agent_marketing_info_interestlevel}</li>}

                    {seller_data.other_agent_marketing_info_howlonghaveyouownedtheproperty && <li><strong>How long have you owned the property?</strong><br />{seller_data.other_agent_marketing_info_howlonghaveyouownedtheproperty}</li>}

                    {seller_data.other_agent_marketing_info_numberof_offers > 0 && <li><strong>Number of Offers</strong><br />{seller_data.other_agent_marketing_info_numberof_offers}</li>}

                    {seller_data.other_agent_marketing_info_whatfeaturemadeyoubuyyourproperty && <li><strong>What feature made you buy your property?</strong><br />{seller_data.other_agent_marketing_info_whatfeaturemadeyoubuyyourproperty}</li>}
                </ul>
            </div>                                        
        </div>
        
        {
            (seller_data.market_research_acheivingnextmov || seller_data.market_research_offmarketopportunities || seller_data.market_research_opportunitiescalled || seller_data.market_research_whichclienttestimonials) && (
                <div className="card-block">
                    <h3>Market Research <a href="javascript:;" className="red_down_arw"><img src={DownArw} alt="" /></a></h3>  

                    <div className="collapse_div">
                        <ul>
                            {seller_data.market_research_acheivingnextmov && <li><strong>Achieving next move</strong><br />{seller_data.market_research_acheivingnextmov}</li>}
                            {seller_data.market_research_offmarketopportunities && <li><strong>Off market opportunities</strong><br />{seller_data.market_research_offmarketopportunities}</li>}
                            {seller_data.market_research_opportunitiescalled && <li><strong>Opportunities called?</strong><br />{seller_data.market_research_opportunitiescalled}</li>}
                            {seller_data.market_research_whichclienttestimonials && <li><strong>Which client testimonials?</strong><br />{seller_data.market_research_whichclienttestimonials}</li>}
                        </ul>
                    </div>                                       
                </div>
            )
        }
        

        <div className="card-block">
            <h3>Forward Purchase Requirements <a href="javascript:;" className="red_down_arw"><img src={DownArw} alt="" /></a></h3>

            <div className="collapse_div">
                <ul>
                    <li><strong>Rebuying?</strong><br />{seller_data.forward_purchase_requirements}</li>
                    {seller_data.forward_purchase_requirements_whatpriceareyoulookingupto > 0 && <li><strong>What price are you looking up to?</strong><br />{seller_data.forward_purchase_requirements_whatpriceareyoulookingupto && '£'+seller_data.forward_purchase_requirements_whatpriceareyoulookingupto}</li>}
                </ul>
            </div>                                          
        </div>

        <div className="card-block">
            <h3>VIC Summary <a href="javascript:;" className="red_down_arw"><img src={DownArw} alt="" /></a></h3>  

            <div className="collapse_div">
                <ul>
                    {seller_data.vca_require_an_agent && <li><strong>Require in an agent...</strong><br />{seller_data.vca_require_an_agent}</li>}
                    {seller_data.vcs_how_to_win_customer && <li><strong>How to win their custom</strong><br />{seller_data.vcs_how_to_win_customer}</li>}
                    {seller_data.vc_ideal_outcome && <li><strong>Ideal outcome from meeting</strong><br />{seller_data.vc_ideal_outcome}</li>}
                    {seller_data.vcs_would_like_to_know && <li><strong>Would like to know...</strong><br />{seller_data.vcs_would_like_to_know}</li>}
                    {seller_data.vca_language && <li><strong>Language, pace, tone to use</strong><br />{seller_data.vca_language}</li>}
                </ul>
            </div>                                       
        </div>   
    </>
    }                                 
    </>
  )
}

export default SellerDetails